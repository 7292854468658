// CSS
import "./App.css";

// React
import React from "react";

// React Icons
import gitbook from "./image/icons/5c36a105-9d3b-4e87-9512-5450524d77cb.png";
import twitter from "./image/icons/twitter (1).png";
import discord from "./image/icons/discord.png";

function App() {
  const [txt, setTxt] = React.useState("Play");

  return (
    <div
      style={{
        backgroundImage: `url(${require("./image/background.png")})`,
        height: "100%",
        minHeight: "100vh",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="w-full flex justify-between md:px-24 px-10 pt-2">
        <img
          src={require("./image/logo.png")}
          alt="logo"
          className="w-32 -mt-2"
        />
        <div
          className="flex my-auto poppins rounded-2xl cursor-pointer bg-gradient-to-r from-[#3461FF] to-[#6F41B7] text-sm lg:text-md"
          onMouseEnter={() => setTxt("Comming Soon")}
          onMouseLeave={() => setTxt("Play")}
        >
          <p className="py-2 px-12 mb-0 text-white">{txt}</p>
        </div>
      </div>
      <div className="flex lg:pr-20 lg:mt-8">
        <div
          className="mx-auto lg:mb-0 lg:mt-0 lg:mr-0 flex thing"
          style={{
            backgroundImage: `url(${require("./image/r.png")})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          <p className="m-auto text-white relative w-6/12 lg:w-7/12 text-center lg:text-3xl text-xl poppins pb-4">
            <span className="linear-text">1000</span> The Seer's trying to
            predict the future of NFTs
          </p>
        </div>
      </div>
      <div className="flex lg:pr-20">
        <div
          className="lg:ml-auto mx-auto flex lg:mr-[8.6rem] bg-white rounded-lg cursor-pointer"
          onClick={() =>
            window.open("https://the-seer.gitbook.io/the-seer/", "_blank")
          }
        >
          <p className="m-auto poppins text-black px-4 py-2">Whitepaper</p>
        </div>
      </div>
      <div className="flex flex-col absolute bottom-3 w-full">
        <div className="flex mx-auto flex-col w-full">
          <p className="text-center poppins text-white text-2xl mx-auto mt-14">
            The Seer, Avalanche's first Predict-to-Earn
          </p>
          <p className="text-center poppins text-2xl mt-3 linear-text-2">
            NFT Project
          </p>
        </div>
        <div className="flex mx-auto space-x-10 mt-4 mb-6 lg:mb-0">
          <img className="w-10 cursor-pointer" src={discord} alt="gitbook" />
          <img
            className="w-10 cursor-pointer"
            src={gitbook}
            alt="gitbook"
            onClick={() =>
              window.open("https://the-seer.gitbook.io/the-seer/", "_blank")
            }
          />
          <img className="w-10 cursor-pointer" src={twitter} alt="gitbook" />
        </div>
      </div>
    </div>
  );
}

export default App;
